const environment = {
	api: {
		url: "https://api-dev.ampmemberships.com",
	},
	localhost: {
		tenantKey: process.env.LOCALHOST_TENANTKEY??"",
	},
	aws: {
		url: "https://dev-ampmemberships-com.s3.us-west-1.amazonaws.com/public",
	},
	appInsights: {
		instrumentationKey: "b67c9f81-ef8a-4450-9c80-007e51863e89" ,
	},
	sentry: {
		dsn: "https://1198be3e7a1543f6aed4aabcfd70bce7@o1295353.ingest.sentry.io/6521245",
	},
	mapbox: {
		apiKey: "pk.eyJ1IjoiaW5zaXRlbnhhbXAiLCJhIjoiY2w2NnZpYXB6MDM0bjNjcno2cjJ2a2o5ZyJ9.FhL2-3SmRc25dEQD7Qc4_Q" ,
	},
};

export default environment;
